import React from "react";
import { sendGA } from "../helpers/tracking";

const Footer = () => {
  return (
    <footer>
      <div className="footer-content container">
        <ul className="social-list">
          <li>
            <a
              className="underlined-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/TeamElinoi"
              onClick={() =>
                sendGA("click", {
                  event_label: "LB - Visite - Facebook",
                })
              }
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              className="underlined-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/elinoi/"
              onClick={() =>
                sendGA("click", {
                  event_label: "LB - Visite - LinkedIn",
                })
              }
            >
              LinkedIn
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
