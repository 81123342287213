import { Link } from "gatsby";
import TransitionLink from "gatsby-plugin-transition-link";
import { Power2, TimelineLite, TweenLite } from "gsap/all";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { sendGA } from "../helpers/tracking";
import logo from "../images/logos/elinoi-logo-label-aqua.svg";

const Header = ({ siteUrl }: { siteUrl: string }) => {
  // STATES
  const [duration] = useState<number>(0.4);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [sharingSite, setSharingSite] = useState<boolean>(false);

  // REFS
  const $menuList = useRef(null);
  const $bgLogo = useRef(null);
  const $innerBurger = useRef(null);
  const $menuContainer = useRef(null);
  const $sharingSiteContent = useRef(null);

  const exitAnimation = (_exit: any, node: any) => {
    TweenLite.to(node, duration, { alpha: 0 });
  };

  const onClickSharingSiteCta = () => {
    const opening = !sharingSite;
    setSharingSite(opening);

    toggleSharingSite(opening);
  };

  const toggleSharingSite = (isOpening: boolean) => {
    const onpening = isOpening ? "+=is-opening" : "-=is-opening";
    const isopen = isOpening ? "+=is-open" : "-=is-open";
    const delay = isOpening ? 0.4 : 0;
    const node = $sharingSiteContent.current;

    TweenLite.set(node, { className: onpening });
    TweenLite.set(node, { className: isopen, delay });
  };

  const onClickMenu = () => {
    setIsOpen(!isOpen);

    if (!isOpen) {
      openMenu();
    } else {
      closeMenu();
    }
  };

  const openMenu = () => {
    const x = $menuContainer.current.offsetWidth;
    const d = 0.6;
    const tl = new TimelineLite({
      ease: Power2.easeInOut,
    });

    tl.fromTo([$menuList.current, $bgLogo.current, $innerBurger.current], d, { x }, { x: 0 }).to(
      $menuContainer.current,
      d,
      { x: 0 },
      0,
    );
  };

  const closeMenu = () => {
    const x = $menuContainer.current.offsetWidth;
    const d = 0.6;

    const tl = new TimelineLite({
      ease: Power2.easeInOut,
    });

    tl.to([$menuList.current, $bgLogo.current, $innerBurger.current], d, { x }).to(
      $menuContainer.current,
      d,
      { x: -x },
      0,
    );
  };

  const onClickMenuList = () => {
    setIsOpen(false);
    closeMenu();
  };

  return (
    <header>
      <div className="header-content">
        <div className="burger-container">
          <div className={`burger ${isOpen ? "is-open" : ""}`} onClick={() => onClickMenu()}>
            <span className="line top"></span>
            <span className="line bottom"></span>
          </div>
        </div>
        <div className="title-container">
          <h1>
            <TransitionLink
              to="/"
              exit={{
                length: duration,
                trigger: ({ exit, node }: { exit: any; node: any }) => exitAnimation(exit, node),
              }}
              entry={{
                delay: duration,
              }}
            >
              <img className="logo-svg" src={logo} alt="Élinoï" />
            </TransitionLink>
          </h1>
        </div>

        <div className="sharing-site-container">
          <div className="sharing-site">
            <div ref={$sharingSiteContent} className="sharing-site-content">
              <span className="text sharing-cta underlined-link" onClick={() => onClickSharingSiteCta()}>
                Partager
              </span>
              <div className="sharing-options-container">
                <ul className="sharing-list">
                  <li className="sharing-item">
                    <a
                      className="facebook"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://www.facebook.com/sharer/sharer.php?u=${siteUrl}`}
                      onClick={() =>
                        sendGA("click", {
                          event_label: "LB - Partage - Facebook",
                        })
                      }
                    >
                      <span>
                        <FaFacebookF />
                      </span>
                    </a>
                  </li>
                  <li className="sharing-item">
                    <a
                      className="linkedin"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://www.linkedin.com/sharing/share-offsite/?url=${siteUrl}`}
                      onClick={() =>
                        sendGA("click", {
                          event_label: "LB - Partage - LinkedIn",
                        })
                      }
                    >
                      <span>
                        <FaLinkedinIn />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`menu-container ${isOpen ? "is-open" : ""}`} ref={$menuContainer}>
        <div className="inner-menu-burger-wrapper">
          <div className="inner-menu-burger-container">
            <div className="burger-container" ref={$innerBurger}>
              <div className={`burger ${isOpen ? "is-open" : ""}`} onClick={() => onClickMenu()}>
                <span className="line top"></span>
                <span className="line bottom"></span>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-logo" ref={$bgLogo}>
          <div></div>
          <div></div>
        </div>
        <ul className="menu-list" ref={$menuList} onClick={() => onClickMenuList()}>
          <li>
            <a
              className="underlined-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://elinoi.com/?utm_source=livre%20blanc&utm_medium=ref&utm_campaign=menu"
              onClick={() =>
                sendGA("click", {
                  event_label: "LB - Menu - Elinoï",
                })
              }
            >
              Elinoï
            </a>
          </li>
          <li>
            <a
              className="underlined-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://blog.elinoi.com/"
              onClick={() =>
                sendGA("click", {
                  event_label: "LB - Menu - Le Blog",
                })
              }
            >
              Le blog
            </a>
          </li>
          <li>
            <Link
              className="underlined-link"
              to="/"
              onClick={() =>
                sendGA("click", {
                  event_label: "LB - Menu - Recommencer",
                })
              }
            >
              Recommencer
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: "",
};

export default Header;
