/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React, { FunctionComponent } from "react";
import LinkedInTag from "react-linkedin-insight";
import "../styles/global.scss";
import Footer from "./footer";
import Header from "./header";
const ReactPixel = typeof window !== "undefined" ? require("react-facebook-pixel") : null;

if (ReactPixel) {
  ReactPixel.default.init(String(process.env.GATSBY_FACEBOOK_PIXELS_ID));
}
if (typeof window !== "undefined") {
  LinkedInTag.init(process.env.GATSBY_LINKEDIN_PARTNER_ID);
}

const Layout: FunctionComponent = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
    }
  `);

  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
        <symbol id="logo" viewBox="0 0 680.7 180.4">
          <path
            fill="#ffffff"
            d="M111.5 125.8H35c3.6 20 20 22.1 30.1 22.1h33.8l13.1-.1v31.6h-3.8c-7.5 0-26.7-.2-46.1-.2-37.7.1-62.1-24.5-62.1-62.6C0 77.4 24.5 50 59.6 50c36.9 0 53.4 27.8 53.4 57.3 0 3.9-.4 9-.6 11.1m-76-21.7h41.4c-.8-4.1-2.4-9-6-12.5-3.4-3.4-7.9-5-13.9-5-13-.1-18.9 9.9-21.5 17.5M185.1 179.5h-35.6l-.3-152.2L146.5 1h37.9zM294 179.5l.6-34.9V90.4c0-12.3-.3-21.1-.9-29.5l-.6-11.8h33l.5 11.3c7.6-5.9 17.9-10.1 30.8-10.1 21.6 0 46.7 14.3 46.7 54.8l.6 74.4H369l-.1-72.1c0-25.3-13.7-25.3-18.2-25.3-10.5 0-17.6 7.7-19.9 15.3l-.1.4c-.6 1.6-.9 4.2-.9 6.8l-.1 74.8-35.7.1zM500.9 181.4c-35.8 0-60.7-26.6-60.7-64.6 0-39.1 23.9-66.5 60.7-66.5 35.9 0 62.8 26.6 62.8 64.6 0 21-6.9 38.4-20 50.4-11.3 10.4-26.5 16.1-42.8 16.1m1.8-116.1c-28.4 0-47.5 20.7-47.5 51.4 0 29.7 18.4 49.6 45.7 49.6 33 0 47.8-25.8 47.8-51.5-.1-29.5-18.5-49.5-46-49.5m-.4 110.4c-23.9 0-52.5-31-52.5-60.1 0-24.4 22-56.3 50.7-56.3 26.8 0 54.4 31.4 54.4 56.3-.1 28.9-29.1 60.1-52.6 60.1m-.1-95.2c-12.3 0-26.7 9.3-26.7 35.6 0 20.4 11 35.2 26.2 35.2s26.2-15 26.2-35.6c.1-12.2-5.3-35.2-25.7-35.2"
          />
          <path
            fill="#303030"
            d="M240.3 51.9c-10.8 0-19.2-8.4-19.2-19.2 0-10.6 8.6-19.2 19.2-19.2 10.5 0 18.7 8.4 18.7 19.2.1 10.7-8.2 19.2-18.7 19.2"
          />
          <path
            fill="none"
            d="M615.9 47c-8 0-14.3-6.3-14.3-14.3 0-7.9 6.4-14.3 14.3-14.3 7.8 0 14 6.3 14 14.3s-6.1 14.3-14 14.3z"
            stroke="#303030"
            strokeWidth="6"
            strokeMiterlimit="10"
          />
          <path fill="#303030" d="M680.7 12c0 10.8-8.3 19.3-18.8 19.3-10.7 0-19-8.5-19-19.3" />
          <path fill="#ffffff" d="M633.7 179.5H598l.2-130.4h35.2zM258.3 179.5h-35.6l-.2-130.4h35.2z" />
          <path
            fill="#303030"
            d="M616 25.1c4 0 7.3 3.3 7.3 7.3s-3.3 7.3-7.3 7.3-7.3-3.3-7.3-7.3 3.3-7.3 7.3-7.3m0-10c-9.6 0-17.3 7.7-17.3 17.3s7.7 17.3 17.3 17.3 17.3-7.7 17.3-17.3-7.7-17.3-17.3-17.3z"
          />
        </symbol>
        <symbol id="logoSmall" viewBox="0 0 945 289.45">
          <path
            className="path"
            d="M0 133c1.66-7.51 3.05-15.09 5-22.51a132.4 132.4 0 0 1 25.78-49.86c13.19-16.17 29-28.89 48-37.52a140.65 140.65 0 0 1 48.39-12.34A134.1 134.1 0 0 1 182 18a126.63 126.63 0 0 1 44.46 26.81 130 130 0 0 1 31 42.28c6 13.2 10.68 26.9 11.49 41.55.58 10.58 1.74 21.27.83 31.75a136.45 136.45 0 0 1-22.67 65.08 129.92 129.92 0 0 1-53.1 45.9c-13.87 6.6-28.54 10.24-44 11.8a136.53 136.53 0 0 1-76.42-14.07c-23.74-11.89-42.08-29.76-55.49-52.67A123.47 123.47 0 0 1 3 175.62c-.88-4.9-2-9.75-3-14.62zM945 17c-1.33 6.12-2.57 12.26-4 18.35a131.29 131.29 0 0 1-26.11 53.3c-11.92 14.77-26.07 26.87-43.06 35.27a135.81 135.81 0 0 1-45.57 13.26c-22.5 2.5-44.09-.57-65.08-8.3-13.35-4.92-25.32-12.45-36.37-21.5-21-17.21-34.88-39-42.94-64.78A128 128 0 0 1 676 0h269zM485.09 289.45c-31.62 0-60.37-9.21-85.58-27.84-27-20-44.5-46.85-52.53-79.67a141.6 141.6 0 0 1-3.86-44.08c2.15-29.26 11.95-55.79 30.39-78.85C386.82 42.38 402.84 28.93 422 19.6A144.55 144.55 0 0 1 535.1 14a134.79 134.79 0 0 1 40.17 23.64 132.71 132.71 0 0 1 35.17 45.45c6.38 13.36 11.22 27.34 12.54 42.26.82 9.17 2.3 18.42 1.84 27.55-1.3 25.72-8 49.9-22 71.94a136.82 136.82 0 0 1-62 53.17 119.81 119.81 0 0 1-38.48 10q-8.63.71-17.25 1.44zm-.16-81.45c34.19.79 58.68-27.18 58.89-59.56.24-36.26-24.07-61-56.67-62.38-35.19-1.43-62.26 28.16-63.2 59.1-1.08 35.66 27.15 63.84 60.98 62.84z"
          />
        </symbol>
        <symbol id="404" viewBox="0 0 291.15 129.784">
          <path d="M76.05 113.384v-19.5h12.3v-22.2h-12.3v-69.3h-26.4L0 73.184v20.7h48.9v19.5zm-25.35-41.7H28.5l22.2-34.8zm228.15 41.7v-19.5h12.3v-22.2h-12.3v-69.3h-26.4l-49.65 70.8v20.7h48.9v19.5zm-25.35-41.7h-22.2l21.9-34.8h.3z" />
          <path d="M146.136 129.784a64.274 64.274 0 0 1-39.056-12.708 62.346 62.346 0 0 1-23.97-36.358 64.565 64.565 0 0 1-1.764-20.117 65.239 65.239 0 0 1 36-53.969 65.946 65.946 0 0 1 51.612-2.558 61.545 61.545 0 0 1 18.331 10.786 60.674 60.674 0 0 1 16.05 20.742 56.4 56.4 0 0 1 5.724 19.287c.372 4.186 1.05 8.405.839 12.571a66.244 66.244 0 0 1-10.046 32.83 62.444 62.444 0 0 1-28.293 24.269 54.79 54.79 0 0 1-17.56 4.564q-3.934.323-7.867.661zm-.074-37.173c15.605.358 26.782-12.4 26.88-27.181.109-16.549-10.985-27.857-25.866-28.466-16.057-.656-28.411 12.851-28.839 26.968-.496 16.275 12.386 29.136 27.824 28.679z" />
        </symbol>
      </svg>
      <Header siteUrl={data.site.siteMetadata.siteUrl} />
      <main>{children}</main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
